import { getMyAxios } from "../utils/my-axios";

export const getUserProfile = async () => {
  try{
    const result = await getMyAxios().get(`/user/me`);
    let error = false;
  
    if(result.status !== 200){
      error = result.data.error;
    }
  
    return { error, data: result.data.data };
  }
  catch(err){
    console.error(err);
    return {error: err, data: null};
  }
};
