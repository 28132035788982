import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

export function getCurrentUser() {
  let user = Cookies.get(process.env.REACT_APP_TOKEN);
  if (!user) {
    return null;
  } else {
    let _user = jwtDecode(user);
    return _user;
  }
}
