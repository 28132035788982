import { Box, Spinner, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {getMyAxios} from "../../utils/my-axios";

const UserTemplateButton = () => {
    const [loading, setLoading] = useState(false);
    async function copySheet() {
        setLoading(true);
        const response = await getMyAxios().get(
        `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/sheets/copy-sheet`);
        
        // Handle response ...
        setLoading(false);
        window.open( "https://docs.google.com/spreadsheets/d/" + response.data.sheetId, '_blank');
    };

  return (
    <div>
        {loading ? (
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="100%"
            h="80%"
            >
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
            />
            </Box>
        ) : 
        <Button
        colorScheme="blue"
        mt="10"
        minW="300px"
        onClick={() => copySheet()}
        >
        Use Template
        </Button>}
    </div>
  );
};

export default UserTemplateButton;
