import { Box, Center, Spinner, Text,Container } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import LinkBankAccount from '../../components/account';
import PaymentPlan from '../../components/payment';
import PrimaryLayout from '../../layout/primary-layout';
import CopyUserTemplate from '../../components/google-sheet';
import { getUserProfile } from '../../services/user';
import { AiFillCheckCircle } from 'react-icons/ai';
import UserPanel from '../../components/user-panel/index';
import { useStore } from '../../services/store/store-context';

const AppDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [uiState, setState] = useState(1);
  const { state, dispatch } = useStore();

  const fetchUserDetails = async () => {
    let { error, data } = await getUserProfile();

    if (error) {
      return;
    }

    if (data != null && data.premium) {
      dispatch({type: 'user', payload: data});
      let _state = 2;

      if (data.plaidConnected) {
        _state = 3;
      }

      setState(_state);
    }
    else if(data == null){
      
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <PrimaryLayout>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="100%"
          h="80%"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Box>
      ) : uiState === 1 ? (
        <PaymentPlan />
      // ) : state === 2 ? (
      //   <LinkBankAccount />
      ) : (
        <Container className="p-0" w="90%">
          <UserPanel/>
        </Container>
      )}
      {/* <PaymentPlan /> */}
    </PrimaryLayout>
  );
};

export default AppDashboard;
