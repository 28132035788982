import React, {useRef} from 'react';
import { Box } from '@chakra-ui/react';
import Welcome from './welcome';
import ConnectedAccountWrapper from './connected-accounts-wrapper';
import LinkedBlueprintsWrapper from './linked-blueprints-wrapper';
import '../../custom-styles/user-panel-styles.css';

const UserPanel = () => {
    var accountRef = useRef();
    var blueprintRef = useRef();

    function scrollToEventHandler(scrollTo){
        if(scrollTo == 'LinkedAccount'){
            accountRef.current.scrollIntoView({behavior:"smooth", block: 'center'});
        }
        else if(scrollTo == 'Blueprints'){
            blueprintRef.current.scrollIntoView({behavior:"smooth", block: 'center'});
        }
    }

    return (
        <Box overflowX='auto'>
            <Welcome scrollTo={scrollToEventHandler}/>
            <div ref={accountRef}>
            <ConnectedAccountWrapper/>
                </div>
                <div  ref={blueprintRef}>
                <LinkedBlueprintsWrapper/>
                </div>
        </Box>
    )
}

export default UserPanel;