import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Link
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useEffect, useState } from 'react';
import { getUserProfile } from '../services/user';
import Cookies from 'js-cookie';
import { useStore } from '../services/store/store-context';
import { getMyAxios } from "../utils/my-axios";

const Navbar = () => {
  const [userData, setUserData] = useState(null);
  const { state } = useStore();
  
  async function getUser() {
    let _user = state.user;
    if (_user == null) {
      let { data } = await getUserProfile();

      if (data == null) {
        Cookies.remove(process.env.REACT_APP_TOKEN);
        _user = null;
      }
      _user = data;
    }

    setUserData(_user);
  }

  useEffect(() => {
    getUser();
  }, []);

  function logout() {
    Cookies.remove(process.env.REACT_APP_TOKEN);
    window.location.reload();
  }

  async function manageSubscription(){
    var url = `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/payment/manage`;
    const response = await getMyAxios().post(url);
    window.location.href = response.data.url;
  }

  return (
    <Box
      className="navbar-container"
      w="100%"
      minH="100px"
      p="5"
      borderBottom="2px solid gray"
      display="flex"
      flexDirection={['column', 'column', 'row', 'row', 'row']}
      justifyContent="space-between"
      alignItems="center"
      gap="5"
      backgroundColor="#1a535c"
    >
      <Image
        className="brand-icon"
        src="/assets/img/Color-logo-no-background.svg"
        width={['200px', '200px', '240px', '260px', '260px']}
      />

      <Menu>
        <Flex color="whitesmoke" paddingLeft="2" borderRadius="2px" gap="2" alignItems="center">
          Hello {userData != null ? userData["email"] : ""}!{' '}
          <Image
            src={userData != null ? userData["picture"] : ""}
            width="40px"
            height="40px"
            borderRadius="full"
          />
          <MenuButton>
            <ChevronDownIcon />
          </MenuButton>
        </Flex>
        <MenuList>
          <MenuItem onClick={async () => await manageSubscription()}>Manage my Subscription</MenuItem>
          <MenuItem><Link href="https://budsheets.com/contact" target="_blank">Contact Us</Link></MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Navbar;
