import axios from "axios";
import Cookies from 'js-cookie';

export const getMyAxios = () => {
  var token = Cookies.get(process.env.REACT_APP_TOKEN);
  const myAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api`,
    timeout: 100000,
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  return myAxios;
};