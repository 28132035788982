import React from 'react';
import { Box, Text, Button, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel,SimpleGrid,Input } from '@chakra-ui/react';
import { getCurrentUser } from '../../utils/get-current-user';
import { getMyAxios } from "../../utils/my-axios";

const LinkedBlueprintsListItem = ({blueprintItem,refreshBlueprints}) => {
  const [blueprintName,setBlueprintName] = React.useState(null);
  const [showBlueprintName,setshowBlueprintName] = React.useState(false);
  const handleChange = (event) => setBlueprintName(event.target.value)

  function openBlueprint(){
    window.open(blueprintItem.sheetURL, '_blank');
  }
  async function deleteBlueprint(){
    const user = await getCurrentUser();

        getMyAxios().post(
            `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/docs/archiveSheet`,
            {
                user_id: user.id,
                sheet: blueprintItem.sheetId
            }
        ).then((res) => {
          if(res.data.success){
            alert('Blueprint archived');
            refreshBlueprints();
          }
        });
  }
  function showRenameField(){
    setshowBlueprintName(true);
  }
  async function renameBlueprint(){
    setshowBlueprintName(false);
      const user = await getCurrentUser();

      getMyAxios().post(
          `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/docs/renameSheet`,
          {
              user_id: user.id,
              sheet: blueprintItem.sheetId,
              newName: blueprintName
          }
      ).then((res) => {
        if(res.data.success){
          alert('Blueprint renamed');
          refreshBlueprints();
          setBlueprintName('');
        }
      });
  }
  return (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex='1' textAlign='left'>
              <img style={{'float': 'left', 'marginRight': '1rem'}} src="assets/img/excel-icon.png" /><span style={{'fontWeight':'700'}}>{blueprintItem.fileName}</span>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} textAlign='left'>
          <Text>{blueprintItem.description}</Text>
          <br/>
          <SimpleGrid minChildWidth='200px' maxChildWidth='300px' spacing='40px'>
            <Box >
              <Button onClick={() => openBlueprint()} style={{'width': '100%','maxWidth':'300px'}} bgGradient='linear(to-t, #16afa4, #0D8980)' color='#fff' _hover={{ bg: "#16afa4" }}>Open Blueprint</Button>
            </Box>
            <Box >
              <Button onClick={() => deleteBlueprint()} style={{'width': '100%','maxWidth':'300px'}} bgGradient='linear(to-t, darkred, red)' color='#fff' _hover={{ bg: "red" }}>Archive Blueprint</Button>
            </Box>
            <Box >
            <Button onClick={() => showRenameField()} style={{'width': '100%','maxWidth':'300px'}} bgGradient='linear(to-t, #97a832, yellow)' color='#fff' _hover={{ bg: "#97a832" }}>{showBlueprintName?'Hide rename blueprint':'Rename Blueprint'}</Button>
              {showBlueprintName?
              <Box>
                <br/>
                <Text>New name: </Text>
                <Input placeholder='New name' onChange={handleChange} value={blueprintName}/>
                <br/>
                <Button colorScheme='blue' style={{marginTop:"10px"}} onClick={()=>renameBlueprint()}>Submit</Button>
              </Box>:null}
            </Box>
          </SimpleGrid>
          
          
          
        </AccordionPanel>
      </AccordionItem>
      )
}

export default LinkedBlueprintsListItem;