import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

const ErrorPage = () => {
  return (
    <Box textAlign="center" fontSize="xl">
      <Flex
        minH="100vh"
        bg="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          bg="white"
          borderRadius="5px"
          minH="350px"
          minW="350px"
          w={['90vw', '90vw', '50vw', '40vw', '30vw']}
          h="35vh"
          p="10"
        >
          <Center>
            <Image
              src="/assets/img/logo.png"
              width={['200px', '200px', '240px', '260px', '260px']}
            />
          </Center>
          <Text
            mt="5"
            fontSize={['28px', '28px', '30px', '32px', '32px']}
            fontWeight="700"
          >
            Oops!
          </Text>
          <Text
            mt="4"
            fontSize={['18px', '18px', '20px', '20px', '20px']}
            fontWeight="400"
          >
            Something went wrong on our end. Please use another account to
            proceed. If this error presists reach out to us at support.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default ErrorPage;
