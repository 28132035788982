import React, { useEffect, useState } from 'react';
import '../custom-styles/footer-styles.css'

const Footer = () => {
  const [year, setYear] = useState(null);

  function getYear() {
    var year = new Date().getFullYear();
    setYear(year);
  }

  useEffect(() => {
    getYear();
  }, []);

  return (
    <div className="footer-wrapper">
      <div className="footer-item-wrapper">
        <div className='footer-items'>
          <div>Copyright © {year} <a href="https://budsheets.com">Budsheets</a></div>
          <div><a href="https://budsheets.com/terms">Terms</a></div>
          <div><a href="https://budsheets.com/privacy">Privacy</a></div>
        </div>
      </div>
    </div>
  )
}

export default Footer;