import { Box, Center, Text } from '@chakra-ui/react';
import UserTemplateButton from './copy-template';

const CopyUserTemplate = () => {
  return (
    <Box w="100%" h="100%" mb="10">
        <img src="assets/img/Google_Sheets_Logo.svg" className="google-sheet-logo" style={{float: 'left'}}/>
      <Text
        mt="5"
        fontSize={['26px', '26px', '28px', '30px', '32px']}
        fontWeight="700"
        align="center"
      >
        Start with Google Sheets
      </Text>

      {/* <button onClick={() => open()} disabled={!ready}>
        Link account
      </button> */}
      <Center>
        <UserTemplateButton />
      </Center>
    </Box>
  );
};

export default CopyUserTemplate;
