import React, { useEffect, useState } from 'react';
import { Box, Accordion,Container } from '@chakra-ui/react';
import LinkedBlueprintsListItem from './linked-blueprints-list-item';
import { getCurrentUser } from "../../utils/get-current-user";
import { getUserProfile } from '../../services/user';
import Cookies from 'js-cookie';
import { useStore } from '../../services/store/store-context';

const LinkedBlueprintsList = () => {
  const [blueprints, setLinkedBlueprints] = useState([]);
  const { state, dispatch } = useStore();
  
  async function getLinkedBlueprints() {
    let _user = state.user;
    let { error, data } = await getUserProfile();

    if (data == null) {
      Cookies.remove(process.env.REACT_APP_TOKEN);
      _user = null;
    }
    _user = data;
    var _blueprints = [];
    if(_user.blueprints){
      _user.blueprints.forEach(blueprint => {
        if(!blueprint.archive){
            _blueprints.push({
              fileName: blueprint.name?blueprint.name:_user.first_name + ' ' + _user.last_name + ' Blueprint Template',
              description: 'The Blueprint Was Created On ' + new Date(blueprint.dateTime).toDateString() + '. Click The Link Below To Open This Blueprint And Get Started.',
              sheetURL: 'https://docs.google.com/spreadsheets/d/' + blueprint.sheetId,
              sheetId: blueprint.sheetId
            });
        }
        
      });
    }
    setLinkedBlueprints(_blueprints);
  }

  useEffect(() => {
    getLinkedBlueprints();
  }, []);

  return (
    <Container>
      <Accordion allowMultiple>
        {blueprints.map((e, i) => <LinkedBlueprintsListItem key={i} blueprintItem={e} refreshBlueprints={()=>getLinkedBlueprints()} />)}
      </Accordion>
      <br/>
    </Container>
  )
}

export default LinkedBlueprintsList;