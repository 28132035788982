import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import ConnectedAccountsList from './connected-accounts-list';

const ConnectedAccountWrapper = () => {
  return (
    <Box alignItems='center' textAlign='center' marginTop='50px'>
      <Heading as='h2'  className="user-panel-section-heading">Connected Accounts Summary</Heading>
      <ConnectedAccountsList/>
    </Box>
  )
}

export default ConnectedAccountWrapper;