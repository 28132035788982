import React, { useEffect, useState } from 'react';
import { Center, Button, TableContainer } from '@chakra-ui/react';
import { DeleteIcon } from "@chakra-ui/icons"
import { getCurrentUser } from "../../utils/get-current-user";
import { getUserProfile } from '../../services/user';
import Cookies from 'js-cookie';
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from '../global-components/data-table';
import { getMyAxios } from "../../utils/my-axios";
import { useStore } from '../../services/store/store-context';

const ConnectedAccountsList = () => {
  const [tableData, setTableData] = useState([]);
  const { state, dispatch } = useStore();
  async function getTableData() {
    let _user = state.user;
    if (_user == null) {
      let { error, data } = await getUserProfile();

      if (data == null) {
        Cookies.remove(process.env.REACT_APP_TOKEN);
        _user = null;
      }
      _user = data;
    }

    //Get connected account
    const columnHelper = createColumnHelper();

    var dataTables = [];
    var id = 0;
    if (_user.connected_accounts != null) {
      _user.connected_accounts.forEach(connectedAccount => {
        if (connectedAccount != null) {

          var columns = [
            columnHelper.accessor("accountName", {
              cell: (info) => info.getValue(),
              header: connectedAccount.bank_name + " Has Been Connected Since " + new Date(connectedAccount.connection_date).toDateString()
            }),
            columnHelper.accessor("accountNumber", {
              cell: (info) => info.getValue(),
              header: "Account #"
            })
          ];

          var data = [];
          var alreadyExisted = null;
          if (dataTables.length > 0) {
            alreadyExisted = dataTables.find(x => x.bank_name == connectedAccount.bank_name);
            data = alreadyExisted?.data ?? [];
          }
          if (connectedAccount.accounts != null) {
            connectedAccount.accounts.forEach(account => {
              data.push({
                id: account.id,
                accountName: account.name,
                accountNumber: account.mask,
                parent_id: account.parent_id
              });
            });
          }

          data = data.sort(function (a, b) {
            // here a , b is whole object, you can access its property
            //convert both to lowercase
            let x = a.accountNumber.toLowerCase();
            let y = b.accountNumber.toLowerCase();

            //compare the word which is comes first
            if (x > y) { return 1; }
            if (x < y) { return -1; }
            return 0;
          });

          if (alreadyExisted == null) {
            dataTables.push({
              columns: columns,
              data: data,
              bank_name: connectedAccount.bank_name,
              id: id++
            });
          }
        }
      });
    }
    else {
      dataTables = resetTable();
    }

    setTableData(dataTables);
  }

  function removeAccount(table_key, account_id, setDeleteLoading) {
    var dataTables = JSON.parse(JSON.stringify(tableData));
    var _account = dataTables[table_key].data.find(x => x.id == account_id);
    var url = `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/docs/removeAccount?accountId=${account_id}&parentId=${_account.parent_id}`;
    setDeleteLoading(true);
    getMyAxios().get(url).then((res) => {
      updateUserState(res.data.user);

      var index = dataTables[table_key].data.indexOf(_account);
      if (index > -1) {
        dataTables[table_key].data.splice(index, 1);
      }
      if (dataTables[table_key].data.length == 0) {
        var _index = dataTables.indexOf(dataTables[table_key]);
        if (_index > -1) {
          dataTables.splice(_index, 1);
        }
      }
      if (dataTables.length == 0) {
        dataTables = resetTable();
      }
      setTableData(dataTables);
      setDeleteLoading(false);
    });
  }

  function removeBank(bank_name, table_key, setRemoveBankLoading){
    if(bank_name == ''){
      return;
    }
    setRemoveBankLoading(true);
    var dataTables = JSON.parse(JSON.stringify(tableData));
    var url = `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/docs/removeBank?bankName=${bank_name}&email=${state.user.email}`;
    getMyAxios().get(url).then((res) => {
      updateUserState(res.data.data);
      var _index = dataTables.indexOf(dataTables[table_key]);
      if (_index > -1) {
        dataTables.splice(_index, 1);
      }
      if (dataTables.length == 0) {
        dataTables = resetTable();
      }
      setTableData(dataTables);
      setRemoveBankLoading(false);
    })
  }

  function resetTable(){
    var id = 0;
    const columnHelper = createColumnHelper();
    var dataTables = [];
    var columns = [
      columnHelper.accessor("accountName", {
        cell: (info) => info.getValue(),
        header: "No Account has been connected yet"
      }),
      columnHelper.accessor("accountNumber", {
        cell: (info) => info.getValue(),
        header: ""
      })
    ];
    var data = [];
    dataTables.push({
      columns: columns,
      data: data,
      bank_name: '',
      id: id++
    });
    return dataTables;
  }

  function updateUserState(user){
    dispatch({type:'user', payload: user});
  }

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <Center>
      <TableContainer>
        {tableData.map(table => (
          <DataTable key={table.id} bank_name={table.bank_name} table_id={table.id} columns={table.columns} data={table.data} removeAccount={removeAccount} removeBank={removeBank} />
        ))}
      </TableContainer>
    </Center>
  )
}

export default ConnectedAccountsList;