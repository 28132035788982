import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getCurrentUser } from "./get-current-user";
import { getUserProfile } from '../services/user';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ role }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    async function loadUser() {
      let {error, data} = await getUserProfile();
      if(data == null){
        Cookies.remove(process.env.REACT_APP_TOKEN);
      };
      
      let _user = await getCurrentUser();
      
      setUser(_user);
      setLoading(false);
    }

    loadUser();
  }, []);

  if (loading) {
    return <div style={{ backgroundColor: "whitesmoke" }}></div>;
  }

  return user && user.role === role ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
