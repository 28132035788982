import React, { useCallback, useEffect, useState } from "react"
import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react"
import { usePlaidLink } from 'react-plaid-link';
import { getMyAxios } from "../../utils/my-axios";
import { getCurrentUser } from '../../utils/get-current-user';
import { ChevronDownIcon } from "@chakra-ui/icons"

const DataTableMenuList = ({bank_name, table_id, removeBank}) => {
  const [linkToken, setLinkToken] = useState(null);
  const [remove_bank_loading, setRemoveBankLoading] = useState(false);
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth > 500 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);
    const generateToken = async () => {
        const user = await getCurrentUser();

        getMyAxios().post(
            `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/docs/create_link_token`,
            {
                user_id: user.id,
            }
        ).then((res) => {
          setLinkToken(res.data.data.link_token);
        });
      };
      
      const onSuccess = useCallback(async (public_token, metadata) => {
      // send public_token to server
      const response = await getMyAxios().post(
        `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/docs/set_access_token`,
        {
          public_token: public_token,
        }
      );
      // Handle response ...
      window.location.reload();
    }, []);
  
    const config = {
      token: linkToken,
      onSuccess,
    };

    useEffect(() => {
      generateToken();
    }, []);
  
    const { open, ready } = usePlaidLink(config);

    return (
        <Menu>
            <MenuButton isLoading={remove_bank_loading} as={Button} rightIcon={<ChevronDownIcon />}> {userIsDesktop?'Actions':'⚙️'}
            </MenuButton>
            <MenuList>
                <MenuItem onClick={() => { open() }}>Add Bank Account</MenuItem>
                <MenuItem display={bank_name !== ''} onClick={() => removeBank(bank_name, table_id, setRemoveBankLoading)}>Remove Bank</MenuItem>
            </MenuList>
        </Menu>)
}

export default DataTableMenuList;