// StoreContext.js
import { createContext, useReducer, useContext } from 'react';

// Define your initial state
const initialState = {
  // Your initial state here
};

// Create a context for your store
const StoreContext = createContext();

// Create a reducer function to handle state changes
const reducer = (state, action) => {
  state[action.type] = action.payload;
  return state;
};

// Create a custom provider component that uses the Context API
export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

// Create a custom hook to easily access the store's state and dispatch function
export const useStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};