import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import LinkedBlueprintsList from './linked-blueprints-list';

const LinkedBlueprintsWrapper = () => {
  return (
    <Box alignItems='center' textAlign='center' marginTop='50px'>
      <Heading as='h2' className="user-panel-section-heading">Linked Blueprints</Heading>
      <LinkedBlueprintsList/>
    </Box>
  )
}

export default LinkedBlueprintsWrapper;