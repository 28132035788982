import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppDashboard from './pages/app';
import ErrorPage from './pages/error';
import LoginPage from './pages/login';
import SuccessPage from './pages/success';
import ProtectedRoute from './utils/protected-route';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/* <Route element={<ShortLink />} path="/:shortlink" /> */}
          <Route element={<ProtectedRoute />}>
            <Route element={<AppDashboard />} path="/app" exact />
          </Route>

          <Route element={<SuccessPage />} path="/oauth/success" />
          <Route element={<ErrorPage />} path="/oauth/error" />
          <Route element={<LoginPage />} path="/login" />
          <Route element={<Navigate to="/app" replace />} path="/" />

          <Route element={<Navigate to="/" replace />} path="*" />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
