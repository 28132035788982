import {
  Box,
  Button,
  Center,
  Flex, Image,
  Link,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { getGoogleUrl } from '../utils/oauth-google';
import { getUserProfile } from '../services/user';
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getCurrentUser } from "../utils/get-current-user";
import Cookies from 'js-cookie';
import {GoogleLoginButton} from 'react-social-login-buttons';

const LoginPage = () => {
  const from = '/login';
  const [user, setUser] = useState();

  useEffect(() => {
    async function loadUser() {
      let _user = await getCurrentUser();
      if(_user != null){
        let {error, data} = await getUserProfile();
      
        if(data == null){
          Cookies.remove(process.env.REACT_APP_TOKEN);
          _user = null;
        }
      }
      setUser(_user);
    }

    loadUser();
  }, []);

  return (user == null ? (
    <Box textAlign="center">
      <Flex
        minH="100vh"
        bg="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          bg="white"
          borderRadius="5px"
          minH="350px"
          minW="350px"
          w={['90vw', '90vw', '50vw', '40vw', '30vw']}
          h={['40vh', '40vh', '40vh', '40vh', '40vh']}
          p="10"
        >
          <Center>
            <Image
              src="/assets/img/logo.png"
              width={['200px', '200px', '240px', '260px', '260px']}
            />
          </Center>
          <Text
            mt="3"
            fontSize={['18px', '18px', '20px', '20px', '20px']}
            fontWeight="350"
          >
            Login or sign up using your Google account
          </Text>
          <br/>
          <GoogleLoginButton onClick={()=>document.location=getGoogleUrl(from)}/>
          {/* <Link href={getGoogleUrl(from)}>
            <Button colorScheme="blue" mt="10" w="100%">
              <AiOutlineGoogle fontSize="26px" />{' '}
              <Text marginLeft="2" fontSize="18px">
                Sign in with Google
              </Text>
            </Button>
          </Link> */}
        </Box>
      </Flex>
    </Box>
  ) : <Navigate to="/" /> );
};

export default LoginPage;
