import React, { useEffect, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { getCurrentUser } from '../../utils/get-current-user';
import LinkAccountButton from './link-account';
import {getMyAxios} from "../../utils/my-axios";
import UserPanel from '../user-panel/index';

const LinkBankAccount = () => {
  const [linkToken, setLinkToken] = useState(null);

  const generateToken = async () => {
    const user = await getCurrentUser();
    const response = await getMyAxios().post(
      `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/docs/create_link_token`,
      {
        user_id: user.id,
      }
    );
    setLinkToken(response.data.data.link_token);
  };

  useEffect(() => {
    generateToken();
  }, []);

  return (
    <Box w="100%" h="100%" mb="10">
      <Text
        mt="5"
        fontSize={['26px', '26px', '28px', '30px', '32px']}
        fontWeight="700"
        align="center"
      >
        Link your bank account
      </Text>

      {/* <button onClick={() => open()} disabled={!ready}>
        Link account
      </button> */}
      <Center>
        <LinkAccountButton linkToken={linkToken} />
      </Center>

      <UserPanel/>
    </Box>
  );
};

export default LinkBankAccount;
