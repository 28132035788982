import * as React from "react"
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, chakra, Menu, MenuButton, Button, MenuList, MenuItem,Text } from "@chakra-ui/react"
import { TriangleDownIcon, TriangleUpIcon, DeleteIcon } from "@chakra-ui/icons"
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel
} from "@tanstack/react-table"
import DataTableMenuList from '../user-panel/data-table-menu-list';

export function DataTable({ bank_name, table_id, data, columns, removeAccount, removeBank }) {
  const [sorting, setSorting] = React.useState([])
  const [delete_loading, setDeleteLoading] = React.useState(false);
  var _key = table_id;
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  })

  return (
      <Table marginBottom="2rem" id={_key}>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta = header.column.columnDef.meta
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                  >
                   <Text style={{ 'whiteSpace': 'pre-line'}}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    </Text> 
                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                )
              })}
              <Th>
                <DataTableMenuList bank_name={bank_name} table_id={table_id} removeBank={removeBank}/>
              </Th>
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta = cell.column.columnDef.meta
                return (
                  <Td key={cell.id} isNumeric={meta?.isNumeric}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                )
              })}
              <Td>
                <Button onClick={(e) => removeAccount(_key, row.original.id, setDeleteLoading)} isLoading={delete_loading}><DeleteIcon/></Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
  )
}

export default DataTable;