import { Box, Center, Flex, Image, Spinner } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../services/user';
import { useStore } from '../services/store/store-context';

const SuccessPage = () => {
  const navigate = useNavigate();
  const { dispatch } = useStore();

  useEffect(() => {
    setTimeout(async () => {
      var user = Cookies.get(process.env.REACT_APP_TOKEN);
      if(!user){
        const params = new URLSearchParams(window.location.search);
        let token = params.get('token')
        Cookies.set(process.env.REACT_APP_TOKEN, token);
      }

      let { data } = await getUserProfile();

      dispatch({ type: 'user', payload: data });

      navigate('/', { replace: true });
    }, 150);
  }, [navigate]);

  return (
    <Box textAlign="center" fontSize="xl">
      <Flex
        minH="100vh"
        bg="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          bg="white"
          borderRadius="5px"
          minH="350px"
          minW="350px"
          w={['90vw', '90vw', '50vw', '40vw', '30vw']}
          h="35vh"
          p="10"
        >
          <Center>
            <Image
              src="/assets/img/logo.png"
              width={['200px', '200px', '240px', '260px', '260px']}
            />
          </Center>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="100%"
            h="80%"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default SuccessPage;
