import { Box, Button, Text } from '@chakra-ui/react';
import React, {useRef, useEffect} from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { getCurrentUser } from '../../utils/get-current-user';

const PaymentPlanCard = ({ data }) => {
  const buttonRef = useRef(null);
  const user = getCurrentUser();
  
  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, []);

  return (
    <Box
      w={['90vw', '90vw', '40vw', '30vw', '450px']}
      h="500px"
      display="none"
      flexDirection="column"
      borderRadius="5px"
    >
      <Box
        height="150px"
        w="100%"
        bg={`${data?.color}.300`}
        borderRadius="5px 5px 0px 0px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          mt="5"
          fontSize={['24px', '24px', '26px', '28px', '28px']}
          fontWeight="650"
        >
          {data?.name}
        </Text>
      </Box>
      <Box
        height="350px"
        w="100%"
        bg="white"
        border="2px"
        borderColor={`${data?.color}.300`}
        borderRadius="0px 0px 5px 5px"
        p="10"
      >
        {data?.benefits.map(benefit => (
          <Box display="flex" gap="2" alignItems="center" key={benefit} mb="1">
            <AiFillCheckCircle fontSize="18px" color="green" />
            <Text>{benefit}</Text>
          </Box>
        ))}
        <Text
          mt="5"
          align="center"
          fontSize={['24px', '24px', '26px', '28px', '28px']}
          fontWeight="700"
        >
          ${data?.price}/{data?.id}
        </Text>

        <form action={data?.link} method="POST">
          <input type="hidden" name="user" value={user?.email} />
          <input type="hidden" name="plan" value={data?.id} />
          <Button ref={buttonRef} mt="10" colorScheme={data?.color} w="100%" type="submit">Buy</Button>
        </form>
      </Box>
    </Box>
  );
};

export default PaymentPlanCard;
