import { Button } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import {getMyAxios} from "../../utils/my-axios";

const LinkAccountButton = ({ linkToken }) => {
  const onSuccess = useCallback(async (public_token, metadata) => {
    // send public_token to server
    const response = await getMyAxios().post(
      `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/docs/set_access_token`,
      {
        public_token: public_token,
      }
    );
    // Handle response ...
    window.location.reload();
  }, []);

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <Button
      colorScheme="blue"
      mt="10"
      minW="300px"
      onClick={() => open()}
      disabled={!ready}
      _disabled={{ bg: 'lightgray' }}
    >
      Link account
    </Button>
  );
};

export default LinkAccountButton;
