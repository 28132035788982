import React, { useEffect, useState } from 'react';
import { Box, Center, Button, Flex, Text, Heading, Checkbox, Stack, useToast,Grid,GridItem, SimpleGrid } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { getCurrentUser } from "../../utils/get-current-user";
import { getUserProfile } from '../../services/user';
import Cookies from 'js-cookie';
import { getMyAxios } from "../../utils/my-axios";
import { useStore } from '../../services/store/store-context';

const Welcome = ({ scrollTo }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cancel_loading, setCancelLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const { state, dispatch } = useStore();
  const toast = useToast()

  var selectedAccounts = [];

  async function getUser() {
    let _user = state.user;
    if (_user == null) {
      let { error, data } = await getUserProfile();

      if (data == null) {
        Cookies.remove(process.env.REACT_APP_TOKEN);
        _user = null;
      }
      _user = data;
    }

    setUser(_user);
  }

  function getConnectedAccounts() {
    var data = [];
    var user = state.user;
    var already_existing = null;
    if (user.connected_accounts != null) {
      user.connected_accounts.forEach(connectedAccount => {
        already_existing = data.find(x => x.bank_name == connectedAccount.bank_name);
        var _data = { bank_name: connectedAccount.bank_name, accounts: [] };
        if(already_existing != null){
          _data = already_existing;
        }
        if (connectedAccount.accounts != null) {
          connectedAccount.accounts.forEach(account => {
            _data.accounts.push({
              id: account.id,
              accountName: account.name,
              accountNumber: account.mask
            });
          });
          _data.accounts.sort(function (a, b) {
            // here a , b is whole object, you can access its property
            //convert both to lowercase
            let x = a.accountNumber.toLowerCase();
            let y = b.accountNumber.toLowerCase();

            //compare the word which is comes first
            if (x > y) { return 1; }
            if (x < y) { return -1; }
            return 0;
          });
        }
        if(already_existing == null){
          data.push(_data);
        }
      });
    }
    setConnectedAccounts(data);
  }
  async function sendAlertMessage(){
    // copySheet
    
    await copySheet();
  }
  async function copySheet() {
    if (selectedAccounts.length == 0) {
      toast({
        title: "Failed",
        description: "Please add an account first using the actions button",
        status: "error"
      })
      return false;
    }
    setOpen(false);
    setOpenAlert(true);
    // setOpen(false);
    // setLoading(true);
    var url = `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/sheets/copy-sheet?accounts=${selectedAccounts.join(',')}`;
    const response = await getMyAxios().get(url);

    // Handle response ...
    // setLoading(false);
    // window.open("https://docs.google.com/spreadsheets/d/" + response.data.sheetId, '_blank');
    // window.location.reload();
  };

  function onClose() {
    setOpen(false);
  }
  function onCloseAlert(){
    setOpenAlert(false)
  }
  function openAccountsModal() {
    
    setCancelLoading(true);
      getMyAxios().get(`${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/user/validatePlaid?user_id=${user.id}`).then((res) => {
        if(res.data.success){
          
        }else{
          toast({
            title: "Error",
            description: res.data.error+' : '+res.data.accounts.join(',') + '. You can use your other accounts or re-connect the account',
            status: "warning"
          });
        }
        
        setCancelLoading(false);
      });
    getConnectedAccounts();
    setOpen(true);
  }

  function setCheckedItems(e) {
    if (e.target.checked) {
      selectedAccounts.push(e.target.id);
    }
    else {
      const index = selectedAccounts.indexOf(5);
      if (index > -1) {
        selectedAccounts.splice(index, 1);
      }
    }
  }

  async function cancelSubscription() {
    try {
      setCancelLoading(true);
      getMyAxios().post(`${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/payment/cancel`).then((res) => {
        toast({
          title: "Success",
          description: "Your subscription has been canceled",
          status: "success"
        });
        setCancelLoading(false);
        window.location.reload();
      });
    }
    catch (err) {
      console.error("cancelSubscription", err);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Box className="welcome-wrapper" alignItems="center" textAlign="center">
      <Heading as="h1">Welcome, {user != null ? user.first_name : ''} {user != null ? user.last_name : ''}</Heading>
      <br/>
      <Text fontSize='1.5em' color='#888888' style={{'maxWidth': '640px','margin': 'auto'}}>From this control panel you can manage your connected accounts, view your existing Budsheets Blueprints, and create new Blueprints.</Text>
      <br/>
      <Center>
        <Flex gap="2" marginTop='10px' className="welcome-btn-section">
          <SimpleGrid columns={[1, null, 3]} spacing='20px'>
                <Button onClick={() => scrollTo("LinkedAccount")} bgGradient='linear(to-t, #16afa4, #0D8980)' color='#fff' _hover={{ bg: "#16afa4" }}>Go to Connected Accounts</Button>
              <Button onClick={() => scrollTo("Blueprints")} backgroundColor='#EBEBEB'>Go to Blueprints</Button>
              <Button onClick={() => openAccountsModal()} backgroundColor='#FFF' border='1px solid #19A49B' isLoading={loading} loadingText='Generating Blueprint'>Load a new Blueprint</Button>
              
          </SimpleGrid>
          
          
          
        </Flex>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select an Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {connectedAccounts.map((connected_account, i) =>
              <div key={i}>
                <Text>{connected_account.bank_name}</Text>
                <Stack pl={6} mt={1} spacing={1}>
                  {connected_account.accounts.map((account) =>
                    <Checkbox 
                      onChange={(e) => setCheckedItems(e)}
                      key={account.id} id={account.id} data-bank_name={connected_account.bank_name}>
                      {account.accountName} {account.accountNumber}
                    </Checkbox>)}
                </Stack>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button backgroundColor='#FFF' border='1px solid #19A49B' onClick={() => sendAlertMessage()}>Connect Account</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenAlert} onClose={onCloseAlert}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Creating Blueprint....</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Your Budsheets Blueprint is currently being generated. Give us a moment, and we'll notify you via email once it's ready.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseAlert}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Welcome