import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import Navbar from './navbar';
import Footer from './footer';

const PrimaryLayout = ({ children }) => {
  return (
    <Flex minH="100vh" bg="whitesmoke">
      <Container maxW="5xl" bg="white">
        <Navbar />

        <Box mt="10">{children}</Box>
        <Footer/>
      </Container>
    </Flex>
  );
};

export default PrimaryLayout;
